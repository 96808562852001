import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.min.css"
import GdprDialog from "./GdprDialog"
import Input from "./Input"
import cn from "../utils/cn"
import { navigate } from "gatsby"
import config from "../config"

toast.configure({ hideProgressBar: true })

interface FormData {
  firstName: string,
  lastName: string,
  email: string,
  phoneNumber?: string,
  message?: string,
  sms?: boolean,
  tips?: boolean,
  newsletter?: boolean
}

interface InterestFormData {
  projectId: string,
  className?: string
}

const InterestForm: React.FC<InterestFormData> = ({
  projectId,
  className
}) => {
  const { register, handleSubmit, errors } = useForm<FormData>()
  const [ isLoading, setIsLoading ] = useState<boolean>(false)
  const [ showGdprDialog, setShowGdprDialog ] = useState<boolean>(false)
  const [ formData, setFormData ] = useState<FormData | null>(null)

  const submit = async () => {
    if(!formData) return
    setIsLoading(true)

    const data = {
      source: "bonum",
      customer: {
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        phoneNumber: formData.phoneNumber
      },
      message: formData.message,
      options: {
        sms: formData.sms,
        tips: formData.tips,
        newsletter: formData.newsletter
      }
    }

    try {
      const res = await fetch(`${config.apiUrl}/hovedsider/projects/${projectId}/interest`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data)
      })

      const result = await res.json()

      if(!res.ok) {
        throw new Error(result.message || "En ukjent feil oppstod")
      }

      toast.success("Du er nå meldt opp som interessent")
      setShowGdprDialog(false)
      navigate("/takk-for-interessen")
    } catch(e) {
      toast.error(e.message)
    }
    setIsLoading(false)
  }

  const onSubmit = (data: FormData): void => {
    setFormData(data)
    setShowGdprDialog(true)
  }

  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={cn("mx-auto", className)}
      >
        <div className="flex mb-8 md:flex-col md:mb-0">
          <Input
            name="firstName"
            label="Fornavn"
            placeholder="Skriv inn ditt fornavn"
            className="mr-4 flex-1 md:mr-0 md:mb-5"
            required={true}
            register={register}
            errors={errors}
          />

          <Input
            name="lastName"
            label="Etternavn"
            placeholder="Skriv inn ditt etternavn"
            className="ml-4 flex-1 md:ml-0 md:mb-5"
            required={true}
            register={register}
            errors={errors}
          />
        </div>

        <div className="flex mb-8 md:flex-col md:mb-0">
          <Input
            name="email"
            label="E-post"
            placeholder="Skriv inn din e-post addresse"
            className="mr-4 flex-1 md:mr-0 md:mb-5"
            required={true}
            register={register}
            errors={errors}
          />

          <Input
            name="phoneNumber"
            label="Telefonnummer"
            placeholder="Skriv inn ditt telefonnummer"
            className="ml-4 flex-1 md:ml-0 md:mb-5"
            required={false}
            register={register}
            errors={errors}
          />
        </div>

        <Input
          type="textarea"
          name="message"
          label="Melding"
          placeholder="Skriv inn en kommentar"
          required={false}
          register={register}
          errors={errors}
        />

        <div className="mt-4">
          <label
            htmlFor="sms"
            className="flex items-center"
          >
            <input
              type="checkbox"
              name="sms"
              id="sms"
              className="mr-4"
              ref={register({ required: false })}
            />

            Jeg ønsker budvarsel på SMS
          </label>
        </div>

        <div className="mt-4">
          <label
            htmlFor="tips"
            className="flex items-center"
          >
            <input
              type="checkbox"
              name="tips"
              id="tips"
              className="mr-4"
              ref={register({ required: false })}
            />

            Jeg ønsker tips om liknende eiendommer per e-post
          </label>
        </div>

        <div className="mt-4">
          <label
            htmlFor="newsletter"
            className="flex items-center"
          >
            <input
              type="checkbox"
              name="newsletter"
              id="newsletter"
              className="mr-4"
              ref={register({ required: false })}
            />

            Jeg ønsker å motta nyhetsbrev per e-post
          </label>
        </div>

        <button
          type="submit"
          className="btn btn-ghost mt-8 mx-auto block"
        >
          Meld interesse
        </button>
      </form>

      <GdprDialog
        shown={showGdprDialog}
        onAccept={submit}
        onDeny={() => {
          setShowGdprDialog(false)
        }}
        isLoading={isLoading}
        description="Dette skjemaet samler inn ditt navn, din e-post adresse og ditt telefonnummer. Da kan enten vi eller vår megler kontakte deg under behandlingen av henvendelsen."
        consents={[{
          question: "Jeg samtykker til at Bonum kan samle på informasjon i skjemaet.",
          required: true
        }, {
          question: "Jeg samtykker til at jeg kan bli kontaktet av megler per e-post eller telefon.",
          required: true
        }]}
      />
    </>
  )
}

export default InterestForm
