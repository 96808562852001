import React, { FC, CSSProperties } from "react"
import styled from "styled-components"
import Helmet from "react-helmet"
import cn from "../utils/cn"

const Icon = styled.div`
  background-image: url("/img/arrow-white.gif");
  background-size: contain;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  display: inline-block;
  transform: rotate(-45deg) scaleX(-1);
  margin-bottom: -30px;
  margin-left: 6px;
  width: 30px;
  height: 50px;
`

interface FinnLinkProps {
  href: string,
  className?: string,
  style?: CSSProperties
}

const FinnLink: FC<FinnLinkProps> = ({
  href,
  className,
  style
}) => (
  <>
    <Helmet>
      <link
        href="https://fonts.googleapis.com/css?family=Indie+Flower&display=swap"
        rel="stylesheet"
      />
    </Helmet>

    <a
      className={cn(`opacity-50 hover:opacity-100`, className)}
      href={href}
      target="_blank"
      rel="noopener"
      style={style || {}}
    >
      <div className="mb-3">
        <span
          className="text-white text-right text-base"
          style={{ fontFamily: "Indie Flower" }}
        >
          Se annonsen<br />på finn.no
        </span>

        <Icon />
      </div>

      <svg
        focusable="false"
        width="70"
        height="32"
        viewBox="0 0 184 64"
      >
        <title>FINN.no</title>
        <path
          fill="#06bffc"
          d="M179.8 58V6c0-1-.8-1.9-1.9-1.9H66c-1 0-1.9.8-1.9 1.9v53.8H178c1 0 1.8-.8 1.8-1.8"
        />
        <path
          fill="#0063fc"
          d="M22.5 4.2H6C5 4.2 4.2 5 4.2 6v52c0 1 .8 1.9 1.9 1.9H60V41.5C59.9 20.9 43.2 4.2 22.5 4.2"
        />
        <path
          fill="#fff"
          d="M178 0H66c-3.3 0-6 2.7-6 6v17.4C53.2 9.6 38.9 0 22.5 0H6C2.7 0 0 2.7 0 6v52c0 3.3 2.7 6 6 6h172c3.3 0 6-2.7 6-6V6c0-3.3-2.7-6-6-6m1.8 58c0 1-.8 1.9-1.9 1.9H64.1V6c0-1 .8-1.9 1.9-1.9h112c1 0 1.9.8 1.9 1.9v52zM4.2 58V6C4.2 5 5 4.2 6 4.2h16.5c20.6 0 37.4 16.8 37.4 37.4v18.3H6c-1-.1-1.8-.9-1.8-1.9"
        />
        <path
          fill="#fff"
          d="M110.1 21.1h-4.2c-.7 0-1.2.5-1.2 1.2v19.3c0 .7.5 1.2 1.2 1.2h4.2c.7 0 1.2-.5 1.2-1.2V22.3c0-.6-.6-1.2-1.2-1.2M98.1 21.1H83c-.7 0-1.2.5-1.2 1.2v19.3c0 .7.5 1.2 1.2 1.2h4.2c.7 0 1.2-.5 1.2-1.2v-4h7.7c.7 0 1.2-.5 1.2-1.2v-3.2c0-.7-.5-1.2-1.2-1.2h-7.7v-4.9h9.7c.7 0 1.2-.5 1.2-1.2v-3.7c0-.5-.6-1.1-1.2-1.1M160.9 21.1h-4.2c-.7 0-1.2.5-1.2 1.2v9.5l-6.6-10c-.3-.4-.8-.7-1.3-.7h-3.2c-.7 0-1.2.5-1.2 1.2v19.3c0 .7.5 1.2 1.2 1.2h4.2c.7 0 1.2-.5 1.2-1.2v-9.4l6.5 9.8c.3.4.8.7 1.3.7h3.4c.7 0 1.2-.5 1.2-1.2V22.3c-.1-.6-.6-1.2-1.3-1.2M135.5 21.1h-4.2c-.7 0-1.2.5-1.2 1.2v9.5l-6.6-10c-.3-.4-.8-.7-1.3-.7H119c-.7 0-1.2.5-1.2 1.2v19.3c0 .7.5 1.2 1.2 1.2h4.2c.7 0 1.2-.5 1.2-1.2v-9.4l6.5 9.8c.3.4.8.7 1.3.7h3.4c.7 0 1.2-.5 1.2-1.2V22.3c-.1-.6-.6-1.2-1.3-1.2"
        />
      </svg>
    </a>
  </>
)

export default FinnLink
