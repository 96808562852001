import React, { FC, useEffect, useState } from "react"
import { Router } from "@reach/router"
import config from "../../config"
import { Project } from "../../types"
import Img from "../../components/Img"
import marked from "marked"
import Slider from "../../components/Slider"
import PageHeader from "../../components/PageHeader"
import InterestForm from "../../components/InterestForm"
import Link from "../../components/Link"
import ActivityIndicator from "../../components/ActivityIndicator"
import FinnLink from "../../components/FinnLink"
import Meta from "../../components/Meta"

interface ProsjektPageProps {
  path: string,
  projectId?: string
}

const ProsjektPage: FC<ProsjektPageProps> = ({ projectId }) => {
  const [ project, setProject ] = useState<Project>()
  const [ isLoading, setIsLoading ] = useState(true)

  useEffect(() => {
    (async () => {
      try {
        const res = await fetch(`${config.apiUrl}/hovedsider/projects/${projectId}`)
        if(!res.ok || !res.json) throw new Error("En feil oppstod")
        const data = await res.json()
        if(!data) throw new Error("En feil oppstod")
        if(data.showOnBonum) setProject(data)
      } catch(e) {
        console.error(e.message)
      }
      setIsLoading(false)
    })()
  }, [
    projectId
  ])

  if(isLoading) {
    return (
      <div className="h-screen w-full relative">
        <ActivityIndicator shown={true}>
          Laster inn prosjektet..
        </ActivityIndicator>
      </div>
    )
  }

  if(!project) {
    window.location.href = "/404"
    return null
  }

  const gallery = project.gallery
    ? project.gallery.slice(1)
    : []

  const featuredImage = project.gallery
    ? project.gallery[0]
    : null

  return (
    <>
      <Meta
        description={project.no.description}
        image={featuredImage?.url}
        pathName={project.slug}
        {...project}
      />

      <PageHeader />

      <main id="prosjekt">
        <div className="relative">
          {project.finnLink && (
            <FinnLink
              className="absolute bottom-0 left-1/2 mb-6"
              href={project.finnLink}
              style={{
                translate: "transformX(-50%)"
              }}
            />
          )}

          {featuredImage && (
            <Img
              className="w-full object-cover object-center"
              src={featuredImage.url}
              alt={featuredImage.alt || `Bilde av ${project.title}`}
              style={{ maxHeight: "66vh" }}
            />
          )}
        </div>

        {project.no.description && project.no.description !== "" && (
          <section
            id="beskrivelse"
            className="px-6 py-32 md:py-16 text-center md:text-left"
          >
            <div className="w-full mx-auto max-w-3xl">
              <h1 className="title">
                {project.title}
              </h1>

              <hr className="sep" />

              <div
                className="markdown"
                dangerouslySetInnerHTML={{
                  __html: marked(project.no.description)
                }}
              />

              {project.videoUrl && (
                <Link
                  className="mt-8 btn-ghost"
                  to={project.videoUrl}
                >
                  Se video
                </Link>
              )}
            </div>
          </section>
        )}

        {gallery.length > 0 && (
          <section
            id="galleri"
            className="w-full bg-black"
          >
            <Slider options={{ lazy: true }}>
              {gallery.map(({ url, alt }) => (
                <div
                  key={url}
                  className="swiper-lazy"
                >
                  <img
                    className="w-full object-cover object-center"
                    style={{ height: "85vh", minHeight: 250 }}
                    src={url}
                    alt={alt}
                  />
                </div>
              ))}
            </Slider>
          </section>
        )}

        {project.submitNewInterests ? (
          <>
            <section
              id="meld-interesse"
              className="px-6 py-16 md:py-8 border-t-2 border-solid border-gray-100"
            >
              <div className="mx-auto max-w-xl text-center md:text-left">
                <h2 className="subtitle">
                  Meld interesse
                </h2>

                <p className="mt-8">Dersom du ønsker å følge med på utviklingen av prosjektet kan du melde din interesse her. Avhengig av hvilken fase prosjektet befinner seg i, vil enten vår prosjektleder kontakte deg, eller den utlyste megleren.</p>
              </div>

              <InterestForm
                className="text-sm mt-16 mx-auto max-w-xl"
                projectId={project._id}
              />
            </section>

            {project.agents && project.agents.length > 0 && (
              <section
                id="meglere"
                className="px-6 py-16 md:py-8 border-t-2 border-solid border-gray-100 text-center"
              >
                <div className="mx-auto max-w-xl">
                  <h2 className="subtitle md:text-center">
                    Kontakt megler
                  </h2>

                  <p className="mt-8">Dersom du ønsker en raskere tilbakemelding på din henvendelse, kan du ta kontakt direkte med vår utlyste megler.</p>
                </div>

                <div className="mt-12 mx-auto max-w-xl flex justify-center flex-wrap">
                  {project.agents.map(({
                    _id,
                    firstName,
                    lastName,
                    email,
                    phone,
                    firm,
                    position
                  }) => (
                    <div
                      key={_id}
                      className="mb-8 last:mb-0 flex flex-col items-center w-1/2 md:w-full"
                    >
                      <p className="text-sm">
                        {position}
                      </p>

                      <h3 className="text-xl font-semibold">
                        {firstName} {lastName}
                      </h3>

                      <p>
                        {firm}
                      </p>

                      <Link
                        to={`mailto://${email}`}
                        target="_blank"
                        rel="nofollower noreferrer"
                        className="inline text-sm"
                      >
                        {email}
                      </Link>

                      <Link
                        to={`tlf://${phone}`}
                        target="_blank"
                        rel="nofollower noreferrer"
                        className="inline text-sm"
                      >
                        {phone}
                      </Link>
                    </div>
                  ))}
                </div>
              </section>
            )}
          </>
        ) : (
          <div className="py-32 px-6 md:py-16 mx-auto max-w-md text-center">
            <div className="mb-8">
              Prosjektet er sluttsolgt. Følg med for liknende prosjekter.
            </div>

            <Link
              to="/prosjekter"
              className="btn btn-ghost"
            >
              Se flere prosjekter
            </Link>
          </div>
        )}
      </main>
    </>
  )
}

export default () => (
  <Router basepath="/prosjekter/">
    <ProsjektPage path=":projectId" />
  </Router>
)
